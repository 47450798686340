.mobile-nav{
    background-color: #DC3545;
    position: sticky;
    bottom: 0;
    display: none;
}

.mobile-nav__menu ul {
    display: flex;
    justify-content: space-evenly;
    padding: 13px;
}

.mobile-nav__menu ul li{
    list-style: none;
}

.mobile-nav__menu ul li a {
    text-align: center;
    margin: auto;
    /* font-size: 15px; */
    color: #fff;
    text-decoration: none;
    /* font-weight: bold; */

    /* text-align: center;
    margin: 6px;
    font-size: 15px;
    color: #fff;
    text-decoration: none;
    font-weight: bold; */
}

.mobile-nav__menu ul li a svg{
        /* width: 20px; */
        text-align: center;
        width: 100%;
        display: flex;
        font-size: 23px;
}


@media only screen and (max-width: 600px) {
    .mobile-nav{
        display: block;
    }
}