/********** Template CSS **********/

:root {
  --primary: #0093dd;
  --light: #f0fbfc;
  --dark: #181d38;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p {
  font-family: "inter", sans-serif !important;
}

.fw-medium {
  font-weight: 600 !important;
}

.fw-semi-bold {
  font-weight: 700 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  transition: 0.5s;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #ffffff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 0px;
}

/*** Navbar ***/
.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

.navbar-light .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  color: #ffffff;
  font-size: 15px;
  text-transform: uppercase;
  outline: none;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--primary) !important;
}

@media (max-width: 991.98px) {
  .navbar-light .navbar-nav .nav-link {
    margin-right: 0;
    padding: 10px 0;
  }

  .navbar-light .navbar-nav {
    border-top: 1px solid #eeeeee;
  }
}

.navbar-light .navbar-brand,
.navbar-light a.btn {
  height: 75px;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--dark);
  font-weight: 500;
}

.navbar-light.sticky-top {
  top: -100px;
  transition: 0.5s;
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    margin-top: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }

  .navbar .dropdown-menu.fade-down {
    top: 100%;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
  }

  .navbar .nav-item:hover .dropdown-menu {
    top: 100%;
    transform: rotateX(0deg);
    visibility: visible;
    transition: 0.5s;
    opacity: 1;
  }
}

/*** Header carousel ***/
@media (max-width: 768px) {
  .header-carousel .owl-carousel-item {
    position: relative;
    min-height: 500px;
  }

  .header-carousel .owl-carousel-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.header-carousel .owl-nav {
  position: absolute;
  top: 50%;
  right: 8%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}

.header-carousel .owl-nav .owl-prev,
.header-carousel .owl-nav .owl-next {
  margin: 7px 0;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: transparent;
  border: 1px solid #ffffff;
  font-size: 22px;
  transition: 0.5s;
}

.header-carousel .owl-nav .owl-prev:hover,
.header-carousel .owl-nav .owl-next:hover {
  background: var(--primary);
  border-color: var(--primary);
}

.page-header {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-header-inner {
  background: rgba(15, 23, 43, 0.7);
}

.breadcrumb-item + .breadcrumb-item::before {
  color: var(--light);
}

/*** Section Title ***/
.section-title {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
}

.section-title::before {
  position: absolute;
  content: "";
  width: calc(100% + 80px);
  height: 2px;
  top: 4px;
  left: -40px;
  background: #da251d;
  z-index: -1;
}

.section-title::after {
  position: absolute;
  content: "";
  width: calc(100% + 120px);
  height: 2px;
  bottom: 5px;
  left: -60px;
  background: #da251d;
  z-index: -1;
}

.section-title.text-start::before {
  width: calc(100% + 40px);
  left: 0;
}

.section-title.text-start::after {
  width: calc(100% + 60px);
  left: 0;
}

/*** Service ***/
.service-item {
  transition: 0.5s;
  display: flex;
  flex-flow: row;
  align-items: center;
}

.service-item:hover {
  margin-top: -10px;
  background: var(--primary);
}

.service-item * {
  transition: 0.5s;
}

.service-item:hover * {
  color: var(--light) !important;
}

/*** Categories & Courses ***/
.category img,
.course-item img {
  transition: 0.5s;
}

.category a:hover img,
.course-item:hover img {
  transform: scale(1.1);
}

/*** Team ***/
.team-item img {
  transition: 0.5s;
}

.team-item:hover img {
  transform: scale(1.1);
}

/*** Testimonial ***/
.testimonial-carousel::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
}

.testimonial-carousel::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
}

@media (min-width: 768px) {
  .testimonial-carousel::before,
  .testimonial-carousel::after {
    width: 200px;
  }
}

@media (min-width: 992px) {
  .testimonial-carousel::before,
  .testimonial-carousel::after {
    width: 300px;
  }
}

.testimonial-carousel .owl-item .testimonial-text,
.testimonial-carousel .owl-item.center .testimonial-text * {
  transition: 0.5s;
}

.testimonial-carousel .owl-item.center .testimonial-text {
  background: var(--primary) !important;
}

.testimonial-carousel .owl-item.center .testimonial-text * {
  color: #ffffff !important;
}

.testimonial-carousel .owl-dots {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border: 1px solid #cccccc;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  background: var(--primary);
  border-color: var(--primary);
}

/*** Footer ***/
.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  font-weight: normal;
  border: 1px solid #ffffff;
  border-radius: 35px;
  transition: 0.3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #ffffff;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}
.customheight img {
  width: 100%;
}
.testcar {
  margin-top: 10em;
}

@media screen and (max-width: 680px) {
  .customheight img {
    height: 50vh !important;
    /* width: auto !important; */
  }
  .carousel-item {
    height: 50vh !important;
  }
  .testcar {
    margin-top: 5em;
  }
}

/* navbar css strat */

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}
.navhead {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.navicon {
  color: rgb(30, 32, 45);
}
.navicon > a {
  color: #484848;
  font-weight: 900;
  font-size: 20px !important;
}
.navicon > a > i {
  color: #0093dd !important;
}
.navbtn {
  color: #484848 !important;
  border-color: #0093dd !important;
  font-size: 1.3rem !important;
  border-radius: 40px !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  margin-top: -10px !important;
  font-weight: 900;
}

.navbarlinks {
  z-index: 9999;
  display: flex;
  flex-flow: row;
  background-color: #cf2030;
  color: #fff;
}
.uppermainheader {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;
}
.navhover {
  transition: linear 0.3s !important;
  margin-left: 3rem;
  margin-right: 3rem;
}

.navhovlinks:hover {
  cursor: pointer;
  transition: linear 0.3s;
  color: #0082dd !important;
}
.navactive {
  display: block !important;
}
.navnotactive {
  display: none !important;
}
.customicon {
  font-size: 1.4rem;
  color: #fff;
  text-shadow: -1px -1px 0 #cf2030, 1px -1px 0 #cf2030, -1px 1px 0 #cf2030,
    1px 1px 0 #cf2030;
  transition: text-shadow 0.1s, color 0.5s;
}
.socialhead {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
}
.social2 {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-left: 3rem;
}
.menuhead {
  display: flex;
  flex-flow: row;
  justify-content: end;
}
.mainlinks {
  /* font-weight: 900; */
  font-size: 1.12rem;
}
.mainlinks::after {
  content: "" !important;
  border-right: 2px solid #000 !important;
  width: 1px !important;
  margin: 0 5px 0 10px !important;
  border-top: 0 !important;
  vertical-align: 0 !important;
  display: inline !important;
}
.menuhead > span {
  font-size: 1.8rem;
  margin-top: -0.8rem;
}
.dropdown-item:hover {
  color: #0082dd !important;
  background-color: #e6f8fa !important;
}

@media (max-width: 792px) {
  .dashboard-app {
    margin-left: 238px;
  }
  .outermobilehead {
    display: block !important;
    margin-bottom: 2rem;
  }
  .newmobilehead {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
  #mainnavsec {
    display: none !important;
  }
  .navhead {
    display: none !important;
  }
  .navbarlinks {
    display: none !important;
  }
}

/* navbar css ended */

/* Begin float */

.float {
  position: fixed;
  bottom: 72px;
  right: 24px;
  text-align: center;
  z-index: 1000;
}

.float .trigger {
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  position: relative;
  background: #0093dd;
  color: #fff;
  height: 60px;
  width: 60px;
  vertical-align: middle;
  animation: 1.5s linear 0s infinite normal pulse;
  transition: ease-in-out 0.2s;
  cursor: pointer;
}

.float .trigger:hover {
  transform: scale(1.1);
}

.float.open .trigger .fa::before {
  content: "\f00d";
}

.float .trigger .fa::after {
  transition: ease-in-out 1s;
}

.fab.open .trigger i {
  transition: all 0.4s ease;
  transform: translateY(5px) rotate(360deg);
}

.float i {
  font-size: 28px;
  line-height: 58px;
}
.back-to-top {
  display: none !important;
}
/* end float */

/* Animations */
@keyframes pulse {
  0% {
    box-shadow: 0px 1px 0px 3px #6ecdd6, 0px 0px 0px 0px #90e1e8;
  }
  30% {
    box-shadow: 0px 1px 0px 3px #6ecdd6, 0px 0px 0px 5px #90e1e8;
  }
  70% {
    box-shadow: 0px 1px 0px 3px #6ecdd6, 0px 0px 0px 15px #90e1e8;
  }
  100% {
    box-shadow: 1px 1px 2px 1px #ab1a1a30, 0px 0px 0px 20px transparent;
  }
}

/* whychoose css start */

.section-head {
  margin-bottom: 60px;
}
.section-head h4 {
  position: relative;
  padding: 0;
  color: #da251d;
  line-height: 1;
  letter-spacing: 0.3px;
  font-size: 34px;
  font-weight: 700;
  text-align: center;
  text-transform: none;
  margin-bottom: 30px;
}
.section-head h4:before {
  content: "";
  width: 60px;
  height: 3px;
  background: #da251d;
  position: absolute;
  left: 0px;
  bottom: -10px;
  right: 0;
  margin: 0 auto;
}
.section-head h4 span {
  font-weight: 700;
  padding-bottom: 5px;
  color: #2f2f2f;
}
p.service_text {
  color: #cccccc !important;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}
.section-head p,
p.awesome_line {
  color: #818181;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}

.extra-text {
  font-size: 34px;
  font-weight: 700;
  color: #2f2f2f;
  margin-bottom: 25px;
  position: relative;
  text-transform: none;
}
.extra-text::before {
  content: "";
  width: 60px;
  height: 3px;
  background: #0093dd;
  position: absolute;
  left: 0px;
  bottom: -10px;
  right: 0;
  margin: 0 auto;
}
.extra-text span {
  font-weight: 700;
  color: #0093dd;
}
.customitem {
  background: #fff;
  text-align: center;
  padding: 20px 25px;
  -webkit-box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  border: 5px solid rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}
.customitem:hover {
  /* background:#0093dd; */
  box-shadow: 0 9px 20px 2px rgba(0, 68, 255, 0.2);
  border: 5px solid rgba(0, 68, 255, 0.2);
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}
/* .customitem:hover .customitem, .customitem:hover span.icon{
      background:#fff;
      border-radius:10px;
      -webkit-transition: all .5s ease 0;
      transition: all .5s ease 0;
      transition: all 0.5s ease 0s;
  } */
/* .itcustomitemem:hover h6{
      color:#fff !important;
      -webkit-transition: all .5s ease 0;
      transition: all .5s ease 0;
      transition: all 0.5s ease 0s;
  } */
.customitem:hover p {
  color: #016bb7 !important;
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}
.customitem .icon {
  font-size: 40px;
  margin-bottom: 25px;
  color: #0093dd;
  width: 90px;
  height: 90px;
  line-height: 96px;
  border-radius: 50px;
}
.customitem .feature_box_col_one {
  background: rgba(247, 198, 5, 0.2);
  color: #0093dd;
}
.customitem .feature_box_col_two {
  background: rgba(255, 77, 28, 0.15);
  color: #0093dd;
}
.customitem .feature_box_col_three {
  background: rgba(0, 147, 38, 0.15);
  color: #0093dd;
}
.customitem .feature_box_col_four {
  background: rgba(0, 108, 255, 0.15);
  color: #0093dd;
}
.customitem .feature_box_col_five {
  background: rgba(146, 39, 255, 0.15);
  color: #0093dd;
}
.customitem .feature_box_col_six {
  background: rgba(23, 39, 246, 0.15);
  color: #0093dd;
}
.customitem p {
  font-size: 15px;
  line-height: 26px;
}
.customitem h6 {
  margin-bottom: 20px;
  color: #2f2f2f;
}
.mission p {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 28px;
  font-weight: 500;
}
.mission i {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #0093dd;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
}
.mission .small-text {
  margin-left: 10px;
  font-size: 13px;
  color: #666;
}
.skills {
  padding-top: 0px;
}
.skills .prog-item {
  margin-bottom: 25px;
}
.skills .prog-item:last-child {
  margin-bottom: 0;
}
.skills .prog-item p {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
}
.skills .prog-item .skills-progress {
  width: 100%;
  height: 10px;
  background: #e0e0e0;
  border-radius: 20px;
  position: relative;
}
.skills .prog-item .skills-progress span {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #0093dd;
  width: 10%;
  border-radius: 10px;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.skills .prog-item .skills-progress span:after {
  content: attr(data-value);
  position: absolute;
  top: -5px;
  right: 0;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  padding: 3px 7px;
  border-radius: 30px;
}

/* whychoose css ended */

/* stpes css start */

.main-timeline {
  position: relative;
}

.main-timeline:before {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  /* background: #da251d; */
  border: 2px dashed #da251d;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.main-timeline .timeline {
  margin-bottom: 0px;
  position: relative;
}

.main-timeline .timeline:after {
  content: "";
  display: block;
  clear: both;
}

.main-timeline .icon {
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.main-timeline .icon:before,
.main-timeline .icon:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.33s ease-out 0s;
}

.main-timeline .icon:before {
  background: #fff;
  border: 2px solid #232323;
  left: -3px;
}

.main-timeline .icon:after {
  border: 2px solid #c6c6c6;
  left: 3px;
}

.main-timeline .timeline:hover .icon:before {
  left: 3px;
}

.main-timeline .timeline:hover .icon:after {
  left: -3px;
}

.main-timeline .date-content {
  width: 50%;
  float: left;
  margin-top: 22px;
  position: relative;
}

.main-timeline .date-content:before {
  content: "";
  width: 21.5%;
  height: 2px;
  background: #c6c6c6;
  margin: auto 0;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
}

.main-timeline .date-outer {
  /* width: 125px;
    height: 125px; */
  font-size: 16px;
  text-align: center;
  margin: auto;
  z-index: 1;
}

.main-timeline .date-outer:before,
.main-timeline .date-outer:after {
  content: "";
  /* width: 100px;
    height: 100px; */
  margin: 0 auto;
  border-radius: 50%;
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.33s ease-out 0s;
}

.main-timeline .date-outer:before {
  /* background: #fff;
    border: 2px solid #0093dd; */
  left: -6px;
}

.main-timeline .date-outer:after {
  /* border: 2px solid #c6c6c6; */
  left: 6px;
}

.main-timeline .timeline:hover .date-outer:before {
  left: 6px;
}

.main-timeline .timeline:hover .date-outer:after {
  left: -6px;
}

.main-timeline .date {
  width: 100%;
  margin: auto;
  position: absolute;
  top: 27%;
  left: 0;
}

.main-timeline .month {
  font-size: 18px;
  font-weight: 700;
}

.main-timeline .year {
  display: block;
  font-size: 30px;
  font-weight: 700;
  color: #0093dd;
  line-height: 36px;
}

.main-timeline .timeline-content {
  vertical-align: middle;
  width: 50%;
  padding: 20px 0 20px 50px;
  float: right;
}

.main-timeline .title {
  font-size: 29px;
  font-weight: 700;
  line-height: 44px;
  margin: 0 0 15px 0;
}

.main-timeline .description {
  margin-bottom: 0;
}

.main-timeline .timeline:nth-child(2n) .date-content {
  float: right;
}

.main-timeline .timeline:nth-child(2n) .date-content:before {
  left: 10px;
}

.main-timeline .timeline:nth-child(2n) .timeline-content {
  padding: 20px 50px 20px 0;
  /* text-align: right */
}

@media only screen and (max-width: 991px) {
  .main-timeline .date-content {
    margin-top: 35px;
  }
  .main-timeline .date-content:before {
    width: 22.5%;
  }
  .main-timeline .timeline-content {
    padding: 10px 0 10px 30px;
  }
  .main-timeline .title {
    font-size: 17px;
  }
  .main-timeline .timeline:nth-child(2n) .timeline-content {
    padding: 10px 30px 10px 0;
  }
}

@media only screen and (max-width: 767px) {
  .main-timeline:before {
    margin: 0;
    left: 7px;
  }
  .main-timeline .timeline {
    margin-bottom: 20px;
  }
  .main-timeline .timeline:last-child {
    margin-bottom: 0;
  }
  .main-timeline .icon {
    margin: auto 0;
  }
  .main-timeline .date-content {
    width: 95%;
    float: right;
    margin-top: 0;
  }
  .main-timeline .date-content:before {
    display: none;
  }
  .main-timeline .date-outer {
    margin: auto;
    width: 130px;
    height: 110px;
  }
  .main-timeline .date-outer:before,
  .main-timeline .date-outer:after {
    width: 110px;
    height: 110px;
  }
  .main-timeline .date {
    top: 30%;
  }
  .main-timeline .year {
    font-size: 24px;
  }
  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(2n) .timeline-content {
    width: 90%;
    text-align: center;
    padding: 10px 0;
  }
  .main-timeline .title {
    margin-bottom: 10px;
  }
}

/* stpes css ended */

/* mobilehaer css start */

.customcard {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

.customcard-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.customcard-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  text-align: center;
}

.dashboard {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* min-height: 100vh; */
}

.dashboard-app {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 2;
  -webkit-flex-grow: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  margin-top: 84px;
}

.dashboard-content {
  -webkit-box-flex: 2;
  -webkit-flex-grow: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  padding: 25px;
}

.dashboard-nav {
  min-width: 238px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  background-color: #0093dd;
}

.dashboard-compact .dashboard-nav {
  display: none;
}

.dashboard-nav header {
  min-height: 84px;
  padding: 8px 27px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dashboard-nav header .menu-toggle {
  display: none;
  margin-right: auto;
}

.dashboard-nav a {
  color: #515151;
}

.dashboard-nav a:hover {
  text-decoration: none;
}

.dashboard-nav {
  background-color: #0093dd;
}

.dashboard-nav a {
  color: #fff;
}

.brand-logo {
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  font-size: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #515151;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brand-logo:focus,
.brand-logo:active,
.brand-logo:hover {
  color: #dbdbdb;
  text-decoration: none;
}

.brand-logo i {
  color: #d2d1d1;
  font-size: 27px;
  margin-right: 10px;
}

.dashboard-nav-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dashboard-nav-item {
  min-height: 56px;
  padding: 8px 20px 8px 70px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.02em;
  transition: ease-out 0.5s;
}

.dashboard-nav-item i {
  width: 36px;
  font-size: 19px;
  margin-left: -40px;
}

.dashboard-nav-item:hover {
  background: rgba(255, 255, 255, 0.04);
}

/* .active {
  background: rgba(0, 0, 0, 0.1);
} */

.dashboard-nav-dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dashboard-nav-dropdown.show {
  background: rgba(255, 255, 255, 0.04);
}

.dashboard-nav-dropdown.show > .dashboard-nav-dropdown-toggle {
  font-weight: bold;
}

.dashboard-nav-dropdown.show > .dashboard-nav-dropdown-toggle:after {
  -webkit-transform: none;
  -o-transform: none;
  transform: none;
}

.dashboard-nav-dropdown.show > .dashboard-nav-dropdown-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.dashboard-nav-dropdown-toggle:after {
  content: "";
  margin-left: auto;
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(81, 81, 81, 0.8);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.dashboard-nav .dashboard-nav-dropdown-toggle:after {
  border-top-color: rgba(255, 255, 255, 0.72);
}

.dashboard-nav-dropdown-menu {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dashboard-nav-dropdown-item {
  min-height: 40px;
  padding: 8px 20px 8px 70px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  transition: ease-out 0.5s;
}

.dashboard-nav-dropdown-item:hover {
  background: rgba(255, 255, 255, 0.04);
}

.menu-toggle {
  position: relative;
  width: 42px;
  height: 42px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #443ea2;
}

.menu-toggle:hover,
.menu-toggle:active,
.menu-toggle:focus {
  text-decoration: none;
  color: #0093dd;
}

.menu-toggle i {
  font-size: 20px;
}

.dashboard-toolbar {
  min-height: 84px;
  background-color: #ffffff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 27px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  z-index: 99999;
}

.nav-item-divider {
  height: 1px;
  margin: 1rem 0;
  overflow: hidden;
  background-color: rgba(236, 238, 239, 0.3);
}

@media (max-width: 792px) {
  .dashboard-app {
    margin-left: 238px;
  }
}

@media (min-width: 992px) {
  .dashboard-compact .dashboard-app {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .dashboard {
    display: block !important;
  }
  .dashboard-content {
    padding: 15px 0px;
  }
}

@media (max-width: 992px) {
  .dashboard-nav {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1070;
  }

  .dashboard-nav.mobile-show {
    display: block;
  }
}

@media (max-width: 992px) {
  .dashboard-nav header .menu-toggle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 992px) {
  .dashboard-toolbar {
    left: 238px;
  }

  .dashboard-compact .dashboard-toolbar {
    left: 0;
  }
}

/* mobilehaer css ended */

/* new testi css start */

.owl-carousel .owl-nav button.owl-prev {
  background-color: #0082dd !important;
  color: white !important;
  padding: 8px !important;
}
.owl-carousel .owl-nav button.owl-next {
  background-color: #0082dd !important;
  color: white !important;
  padding: 8px !important;
}
/* .img-fluid {
  max-width: 100% !important;
  height: auto !important;
} */
@media (max-width: 692px) {
  .owl-carousel .owl-item img {
    width: 55% !important;
  }
}

/* new testi css ended */

/* sticky call start */
.sticky-icon {
  z-index: 1;
  position: fixed;
  top: 75%;
  right: 0%;
  width: 220px;
  display: flex;
  flex-direction: column;
}
.sticky-icon a {
  transform: translate(160px, 0px);
  border-radius: 50px 0px 0px 50px;
  text-align: left;
  margin: 2px;
  text-decoration: none;
  text-transform: uppercase;
  padding: 10px;
  font-size: 22px;
  font-family: "Oswald", sans-serif;
  transition: all 0.8s;
}
.sticky-icon a:hover {
  color: #fff;
  transform: translate(0px, 0px);
}

.Facebook {
  background-color: #dc3545;
  color: #fff;
}

.sticky-icon a i {
  background-color: #fff;
  height: 40px;
  width: 40px;
  color: #000;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  margin-right: 20px;
  transition: all 0.5s;
}
.sticky-icon a i.fa-facebook-f {
  background-color: #fff;
  color: #2c80d3;
}

#myBtn {
  height: 50px;
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  text-align: center;
  padding: 10px;
  text-align: center;
  line-height: 40px;
  border: none;
  outline: none;
  background-color: #1e88e5;
  color: white;
  cursor: pointer;
  border-radius: 50%;
}
.fa-arrow-circle-up {
  font-size: 30px;
}

#myBtn:hover {
  background-color: #555;
}

/* .custommodal{
  width:30% !important;
  position:fixed !important;
  bottom:0 !important;
  left: 60% !important;
  top: 50% !important;
  right:0 !important;
  margin:0 !important;
} */
.product-file-type > i {
  width: 46px;
  height: 46px;
  line-height: 46px;
  font-weight: 600;
  border-radius: 50%;
  font-size: 18px;
  text-align: center;
}
.media {
  display: flex;
  /* padding-left: 30px; */
  flex-flow: row;
  align-items: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 4px;
}
.media > .fa-phone-square-alt {
  font-size: 40px;
}
.media > .fa-whatsapp-square {
  font-size: 40px;
  color: #25d366;
}
.media > .fa-calendar-alt {
  font-size: 40px;
}
.media:hover {
  background-color: #f1f1f1;
}
/* sticky call ended */

.owl-prev {
  display: none !important;
}

.owl-next {
  display: none !important;
}
.owl-prev i,
.owl-next i {
  transform: scale(1, 6);
  color: #ccc;
}

.testicard {
  border-radius: 10px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.testicontent {
  padding: 5px 10px;
  background: rgb(0, 147, 221);
  background: linear-gradient(
    90deg,
    rgba(0, 147, 221, 0.14627100840336138) 35%,
    rgba(0, 212, 255, 0.3563550420168067) 100%
  );
  border-radius: 10px;
}

/* quicksteps start */
.mainquick {
  background-color: #0093dd;
}
.rightChapterBlockIcon {
  margin: 10px 0 10px;
}
.rightChapterBlockIcon ul {
  display: flex;
  align-items: start;
  justify-content: space-between;
  position: relative;
  width: 100%;
  /* left: -10%; */
}
.rightChapterBlockIcon ul li {
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  flex: 1;
}
.rightChapterBlockIcon ul li p {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin-bottom: 15px;
}
.rightChapterBlockIcon ul li p i {
  background: #fff;
  color: #0093dd;
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.rightChapterBlockIcon ul li:first-child p::after {
  content: "";
  width: 69%;
  height: 1px;
  border-top: 2px dashed #fff;
  position: absolute;
  left: 19%;
  z-index: -1;
}
.rightChapterBlockIcon ul li span {
  color: #fff;
  font-size: 12px;
  text-align: center;
}
@media screen and (max-width: 680px) {
  .home-counsel-btn {
    margin-top: 0 !important;
  }
  .mobile-quickline {
    margin-top: 1rem !important;
  }
}

/* quicksteps ended */

/* testprep start */
.testpreptab {
  background-color: #0093dd !important;
  color: #fff !important;
  border-color: #fff !important;
}
.testpreptab:hover {
  background-color: #dc3545 !important;
  color: #fff !important;
  border-color: #fff !important;
}
.nav-item > .active {
  background-color: #dc3545 !important;
  color: #fff !important;
}
/* testprep ended */

/* blog home start */

/* .card-img-top {
  width: 100%;
  height: 19rem;
  object-fit: cover;
} */
.bloghomeside {
  display: flex;
  flex-flow: column;
}
.blogsideimg {
  width: 18vw !important;
  object-fit: cover;
  height: auto !important;
}
@media screen and (max-width: 680px) {
  .homeblogcard {
    flex-flow: column !important;
  }
  .blogsideimg {
    width: auto !important;
    object-fit: cover;
    height: auto !important;
  }
}

/* blog home ended */

/* faq start */
.accordion-button:not(.collapsed) {
  background-color: #dfe7f6 !important;
  color: #0093dd !important;
}
/* faq ended */

/* test prep start */
@media screen and (max-width: 680px) {
  .testprepimg {
    display: none;
  }
}

/* test prep ended */

/* newmobileheader start */
.mobile-head-body {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100%;
}
/* newmobileheader ended */

/* home carousel start */

@media screen and (max-width: 680px) {
  .customheight img {
    height: 40vh !important;
    /* width: auto !important; */
  }
  .carouseltxt {
    display: none !important;
  }
  .homecarouselinner {
    height: 40vh !important;
  }
  .mobileimg {
    display: block;
  }
  .desktopimg {
    display: none !important;
    width: 100% !important;
  }
}
.btn-primary:hover {
  background-color: #016bb7 !important;
  border-color: #016bb7 !important;
  color: #fff !important;
}

/* home carousel ended */

/* IELTS landing started */

.ullist {
  list-style-type: none !important;
  color: #000 !important;
}
.input-light2 {
  /* background-color: #f6f6f6 !important; */
  border: none !important;
  border-bottom: 1px solid #da251d !important;
}

.input-light2:focus {
  /* background-color: #f6f6f6 !important; */
  border-bottom: 2px solid #da251d !important;
  box-shadow: none !important;
}
.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: auto !important;
}
.fa-check-circle {
  color: #25d366 !important;
  background-color: #fff !important;
  font-size: 22px !important;
  margin-right: 5px;
  border-radius: 100% !important;
}

@media screen and (max-width: 680px) {
  .ieltslandingcontent {
    display: none !important;
  }
  .ieltslandingcontentmobile {
    display: block !important;
  }
  .bg-image {
    height: 750px !important;
  }
  .customcontainer {
    margin-top: 2rem !important;
  }
}

/* IELTS landing ended */

/* 404 page start */

.page_404 {
  background: #fff;
  font-family: "Arvo", serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/722246/screenshots/3066818/404-page.gif);
  height: 400px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #da251d;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}

/* 404 page ended */

/* thankyou start */
.wrapper-1 {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.wrapper-2 {
  padding: 30px;
  text-align: center;
}
.thankyouh1 {
  font-family: "Kaushan Script", cursive;
  font-size: 3em;
  letter-spacing: 3px;
  color: #cf2030;
  margin: 0;
  margin-bottom: 20px;
}
.wrapper-2 p {
  margin: 0;
  font-size: 1.3em;
  color: #aaa;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 1px;
}
.go-home {
  color: #fff;
  background: #cf2030;
  border: none;
  padding: 10px 50px;
  margin: 30px 0;
  border-radius: 30px;
  text-transform: capitalize;
  box-shadow: 0 10px 16px 1px rgb(251, 175, 174);
}
.footer-like {
  margin-top: auto;
  background: #f0fbfc;
  padding: 6px;
  text-align: center;
}
.footer-like p {
  margin: 0;
  padding: 4px;
  color: #0093dd;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 1px;
}
.footer-like p a {
  text-decoration: none;
  color: #0093dd;
  font-weight: 600;
}

@media (min-width: 360px) {
  .thankyouh1 {
    font-size: 3.5em;
  }
  .go-home {
    margin-bottom: 20px;
  }
}

@media (min-width: 600px) {
  .content {
    max-width: 1000px;
    margin: 0 auto;
  }
  .wrapper-11 {
    height: initial;
    max-width: 620px;
    margin: 0 auto;
    margin-top: 50px;
    box-shadow: 4px 8px 40px 8px rgba(88, 146, 255, 0.2);
  }
}
/* thankyou ended */

/* blog start */
.blogherosec {
  padding-left: 3rem;
}
.blogsideimg2 {
  height: 200px;
}

@media screen and (max-width: 680px) {
  .blogherosec {
    padding-left: 0px !important;
  }
  .blog-bg-image {
    height: 300px !important;
  }
  .flag-img {
    width: 50px !important;
  }
}
/* blog ended */

/* blog detail css start */
.entry-card {
  -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
}

.entry-content {
  background-color: #fff;
  padding: 36px 36px 36px 36px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.entry-content .entry-title a {
  color: #333;
}

.entry-content .entry-title a:hover {
  color: #4782d3;
}

.entry-content .entry-meta span {
  font-size: 12px;
}

.entry-title {
  font-size: 0.95rem;
  font-weight: 500;
  margin-bottom: 15px;
}

.entry-thumb {
  display: block;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.entry-thumb img {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.entry-thumb .thumb-hover {
  position: absolute;
  width: 100px;
  height: 100px;
  background: rgba(71, 130, 211, 0.85);
  display: block;
  top: 50%;
  left: 50%;
  color: #fff;
  font-size: 40px;
  line-height: 100px;
  border-radius: 50%;
  margin-top: -50px;
  margin-left: -50px;
  text-align: center;
  transform: scale(0);
  -webkit-transform: scale(0);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.entry-thumb:hover .thumb-hover {
  opacity: 1;
  transform: scale(1);
  -webkit-transform: scale(1);
}

.article-post {
  border-bottom: 1px solid #eee;
  padding-bottom: 70px;
}

.article-post .post-thumb {
  display: block;
  position: relative;
  overflow: hidden;
}

.article-post .post-thumb .post-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  opacity: 0;
}

.article-post .post-thumb .post-overlay span {
  width: 100%;
  display: block;
  vertical-align: middle;
  text-align: center;
  transform: translateY(70%);
  -webkit-transform: translateY(70%);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  height: 100%;
  color: #fff;
}

.article-post .post-thumb:hover .post-overlay {
  opacity: 1;
}

.article-post .post-thumb:hover .post-overlay span {
  transform: translateY(50%);
  -webkit-transform: translateY(50%);
}

.post-content .post-title {
  font-weight: 500;
}

.post-meta {
  padding-top: 15px;
  margin-bottom: 20px;
}

.post-meta li:not(:last-child) {
  margin-right: 10px;
}

.post-meta li a {
  color: #999;
  font-size: 13px;
}

.post-meta li a:hover {
  color: #4782d3;
}

.post-meta li i {
  margin-right: 5px;
}

.post-meta li:after {
  margin-top: -5px;
  content: "/";
  margin-left: 10px;
}

.post-meta li:last-child:after {
  display: none;
}

.post-masonry .masonry-title {
  font-weight: 500;
}

.share-buttons li {
  vertical-align: middle;
}

.share-buttons li a {
  margin-right: 0px;
}

.post-content .fa {
  color: #ddd;
}

.post-content a h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0px;
}

.article-post .owl-carousel {
  margin-bottom: 20px !important;
}

.post-masonry h4 {
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 700;
}
.mb40 {
  margin-bottom: 40px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.media-body {
  padding-left: 15px;
}
.media-body h5 a {
  color: #555;
}

.sidebar-title {
  margin-bottom: 1rem;
  font-size: 2rem;
  text-transform: uppercase;
}

.categories li {
  vertical-align: middle;
}

.categories li > ul {
  padding-left: 15px;
}

.categories li > ul > li > a {
  font-weight: 300;
}

.categories li a {
  color: #999;
  position: relative;
  display: block;
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
}

.categories li a:hover {
  color: #444;
  background-color: #f5f5f5;
}

.categories > li.active > a {
  font-weight: 600;
  color: #444;
}

.media-body h5 {
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 20px;
  font-weight: 400;
}

.media-body h5 a {
  color: #555;
}

.media-body h5 a:hover {
  color: #4782d3;
}
/* blog detail css ended */

/* who we are start */

/* .testprepimg2 img{
  height: 100% !important;
} */
@media screen and (max-width: 680px) {
  .directorsec {
    flex-flow: column-reverse !important;
  }
  .testprepimg2 img {
    height: 250px !important;
  }
  .testprepimg2 {
    min-height: 250px !important;
  }
}
/* who we are start */

/* expertise css start */
@media screen and (max-width: 680px) {
  .expertisetab {
    display: none !important;
  }
  .expertisenav {
    display: block !important;
  }
  .desktopbgchandigarh {
    display: none !important;
  }
  /* .mobilebgchandigarh{
  display: block !important;
} */
  .landingbanner {
    margin-top: 6rem !important;
  }
}
/* expertise css ended */

/* hovermenu start */
.customdropmenu {
  width: 36rem;
  background: #ffffff !important;
}
.customdropmenusec {
  background: #ffffff;
  color: #fff;
}
.droplink:hover {
  color: #0082dd !important;
}

@media screen and (min-width: 680px) {
  .dropdown:hover > .dropdown-menu {
    display: block !important;
  }

  .dropdown > .dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none !important;
  }
}
/* hovermenu ended */

/* university start */

/* #scrollspy1 {
  top: 160px !important;
} */
.scrollspy-example {
  position: relative !important;
}

.bg-light2 {
  background-color: #eef6ff !important;
}

.customlink {
  color: #0093dd !important; /* Set the default color for navigation links */
  transition: color 0.3s !important; /* Add a smooth transition for the color change */
  border-bottom: 1px solid rgb(233, 232, 232) !important;
}
.customlink.active {
  color: #ffffff !important; /* Set the color for navigation links on hover */
  background: #007bff !important;
}

.customlink:hover {
  color: #ffffff !important; /* Set the color for navigation links on hover */
  background: #007bff !important;
}
.mobilescroll {
  display: none !important;
}

@media (max-width: 767px) {
  /* Hide the navigation on mobile */
  #scrollspy1 {
    display: none !important;
  }
  .mobilescroll {
    display: block !important;
  }

  .show-nav #scrollspy1 {
    display: block !important;
  }

  #scrollspy1 .nav-link {
    padding: 5px 15px !important;
    font-size: 14px !important;
  }
  .customh2 {
    font-size: 1.2rem !important;
  }
}

/* profile sec start */

/* .customimg{
  width: 100%;
  height: 100% !important;
} */
.profile-box {
  margin-bottom: 0 !important;
}
@media (max-width: 767px) {
  .profleleft {
    margin-bottom: 2rem;
  }
  .profile-box {
    margin-bottom: 1rem !important;
  }
  .landingformlayout {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    margin-top: 0rem !important;
  }
  .landingbanner {
    display: block !important;
  }
  .customcontainer {
    display: none !important;
  }
  .modal-img {
    display: none !important;
  }
}
.landingformlayout {
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 3rem !important;
}
/* profile sec ended */
.video-container {
  flex-basis: calc(
    20% - 20px
  ); /* Adjust the width percentage and subtract margin */
  margin-bottom: 20px;
}
.studentssec {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
/* test from */
.fixed-tel {
  position: fixed;
  bottom: 80px;
  right: 10px;
  width: 60px;
  height: 60px;
  line-height: 50px;
  z-index: 9999;
  text-align: center;
  color: white;
}

.fixed-tel:before {
  content: "";
  width: 60px;
  height: 60px;
  background-color: #2c2c2c;
  position: absolute;
  border-radius: 100%;
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  z-index: 1;
  top: 0;
  left: 0;
}

.fixed-tel i {
  vertical-align: middle;
  z-index: 2;
  position: relative;
  color: #fff;
  font-size: 1.6rem;
}

.fixed-tel2 {
  position: fixed;
  bottom: 150px;
  right: 10px;
  width: 60px;
  height: 60px;
  line-height: 50px;
  z-index: 9999;
  text-align: center;
}

.fixed-tel2:before {
  content: "";
  width: 60px;
  height: 60px;
  background-color: #dc3545;
  position: absolute;
  border-radius: 100%;
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  z-index: 1;
  top: 0;
  left: 0;
}

.fixed-tel2 i {
  vertical-align: middle;
  z-index: 2;
  position: relative;
  color: #fff;
  font-size: 1.6rem;
}
/* test from */
.infopopup {
  z-index: 999999 !important;
}
.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1020;
}
/* Keyframes for blinking animation */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* Apply the animation to the button */
.blinking-button {
  animation: blink 0.8s infinite;
}

/* loading css */

.slick-slide > div {
  margin: 0 10px !important;
}
.testimonialSlider {
  transition: all 0.3s;
  border-radius: 10px;
}
.testimonialSlider:hover {
  transform: scale(1.04);
  /* background-color: #2a374f; */
  /* box-shadow: 0 5px 10px rgba(148, 148, 148, 0.5); */
}

.sliderText:hover {
  color: #eee !important;
}

@media (min-width: 576px) {
  .yt-carousal-item {
    width: 50%; /* Show 2 items on small screens and above */
  }
}

/* .zsiq_theme1.zsiq_floatmain {
  bottom: 4.6rem !important;
} */

@media only screen and (max-width: 600px) {
  .zsiq_theme1.zsiq_floatmain{
    bottom: 4.6rem !important;
  }
}


